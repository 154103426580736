#headerframe {
    background-color: #2C3531;
    height: 80px;
    width:100%;
    display: flex;
    flex-direction: row;
    position: fixed;
}

#sectionplate {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.sectionbutton {
    position: relative;
    height:100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.hbtxt {
    color: #d1e8e2;
    font-size: 36px;
    text-wrap: nowrap;
    cursor: pointer;
}

.sectionbutton:active {
    transform: translateY(2px);
}

#mylogo {
    height: 100%;
    padding-left: 20px;
}

#mylogo img {
        height: 100%;
        width: auto;
        object-fit: cover;
        background: no-repeat;
}

#hhambtn {
    display:none;
}


@media (max-width: 768px) {
   

    .sectionbutton {
        display: none!important;
    }

    #hhambtn {
        cursor: pointer;
        display:block;
        height: 80px;
        background-color: transparent;
        border: none;
        width: 60px;
        margin-left: auto;
    }

    #hhambtn img {
        height: 40px;
        width: auto;
    }

    #hhambtn img:active {
        transform: translateY(2px);
    }
}
