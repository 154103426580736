.projectsection {
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin-top: 20px;
    margin-bottom: 40px;
}

.textsection {
    display: flex;
    flex-direction: column;
    width: 70vw;
    background-color: #BC986A;
    border-radius: 30px;
    border: 5px solid #8D8741;
    margin-left: 10px;
}

.projecttitle {
    margin-top: 20px;
    font-size: 48px;
    text-align: center;
}

.projectdetails {
    font-size: 24px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.skillssection{
    display: flex;
    flex-direction: column;
    width: 30vw;
    font-family: 'Times New Roman', Times, serif;
    background-color: #DAAD86;
    border-radius: 30px;
    border: 5px solid #947b5a;
    margin-left: 10px;
    margin-right: 20px;
}

.skillstitle{
    margin-top: 20px;
    font-size: 48px;
    text-align: center;
}

.skillslist{
    font-size: 36px;
}

.projectlink{
    font-size: 36px;
    padding-left: 10px;
}