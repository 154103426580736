#projectcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.projectsection {
    padding-top: 50px;
    width: 70%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.pthold {
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ptitle {
    font-size: 70px;
    text-decoration: underline;
    white-space: nowrap;
}

.pdesc {
    font-size: 36px;
}

.techlist {
    font-size: 24px;
}

@media (max-width: 768px) {
    .pdesc{
        font-size: 24px;
    }
}