#main{
  position: fixed;
  background-color: cornsilk;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

#main::-webkit-scrollbar {
  display: none;

}


#Section {
  padding-top: 80px;
  height: 100%;
  overflow-x: scroll;
}

#Section::-webkit-scrollbar {
  display: none;
}