#sbcont {
    position: fixed;
    height:300px;
    width: 30px;
    right: 0;
    top: 25%;
    transition: width 0.1s ease;
    display: flex;
    flex-direction: row;
}

#sbcont.open {
    width: 99px;
}

#handle {
    height:100%;
    width: 100%;
    background-color: gray;
    opacity: .5;
    border: none;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#handletxt {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size:25px;
    text-align: center;

}


#handle:active{
    background-color: rgb(0, 0, 0);
}

#handle.open {
    width:33%;
}

#drawer {
    width: 0%;
    height: 100%;
    background-color: #2C3531;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#drawer.open {
    width: 66%;
}

.draweratag {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.drawerbutton {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 20%;
    cursor: pointer;
}

.drawerbutton img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    #sbcont {
        display: none!important;
    }
}