
#aboutcontainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding-top: 5vh;
}

.detailsplate{
    width: 80%;
    display: flex;
    flex-direction: column;
    height: auto;
}

.text{
    text-align: center;
    width: 100%;
}

.largefont{
    display: block;
    font-size: 5vw;
    white-space: nowrap;
    font-family: "Philospher";
}

.smalltext{
    font-size: 2.5vw;
    display: block;
    white-space: nowrap;
    font-family: "Philospher";
   
}

.imagesection {
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}


.mountains{
    width: 100%;
    height: auto;
    border-radius: 10%;
    display: block;
    margin: auto;
    object-fit: cover;
}




